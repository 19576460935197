import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import JoinGlassHiveCallout from '../../components/JoinGlassHiveCallout';
import ReviewCard from '../../components/ReviewCard';
import ReviewCardFull from '../../components/ReviewCardFull';

import './style.scss';

const IndexPage = () => {
    useEffect(() => {});

    return (
        <Layout navTheme="light">
            <Seo title="Reviews" />
            <div className="reviews-page reach">
                <Container className="purple-swish-background container-1">
                    <Row className="center">
                        <Col className="content centered-text max-width-1100">
                            <h1 className="white-text">
                                Testimonials From Satisfied GlassHive Users
                            </h1>
                            <p className="hero-subtext white-text">
                                See what GlassHive has done for countless MSPs
                                like yours
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-11">
                    <Row className="review-row flexed-mobile stretch review-row-1 z-index-999">
                        <Col className="one-half small-width">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelectorAll(
                                            '.review-row-1 .review-card'
                                        )
                                        .forEach(card => {
                                            card.classList.add('slide-up');
                                        });
                                }}
                            />
                            <ReviewCard
                                id="95ww5okt9u"
                                thumbnailUrl={require('../../images/reviews/dennis-crupi.png?image_crop_resized=640x358')}
                                clientCompany="Ingram Micro"
                                clientName="Dennis Crupi"
                                description="Having an Agency partner that not only understands the marketing strategies that will succeed for our channel partners but also understands the operational side of what our marketing business needs to run well has been invaluable to us."
                            />
                        </Col>
                        <Col className="one-half small-width">
                            <ReviewCard
                                id="0py9uw5e5r"
                                thumbnailUrl={require('../../images/reviews/lauren-jenkins.png?image_crop_resized=640x358')}
                                clientCompany="Ingram Micro"
                                clientName="Lauren Jenkins "
                                description="Prior to GlassHive, we had limited visibility into how the campaigns were performing and what kind of impact we were making."
                            />
                        </Col>
                    </Row>
                    <Row className="review-row flexed-mobile stretch review-row-1 z-index-999">
                        <Col className="one-half small-width">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelectorAll(
                                            '.review-row-1 .review-card'
                                        )
                                        .forEach(card => {
                                            card.classList.add('slide-up');
                                        });
                                }}
                            />
                            <ReviewCard
                                id="p4b1av1y5c"
                                thumbnailUrl={require('../../images/reviews/blue-river.jpg?image_crop_resized=640x358')}
                                clientCompany="Blue River Tech Partners"
                                clientName="Joseph Pena"
                                description="It gives you a ton of information that I didn't even know you could get."
                            />
                        </Col>
                        <Col className="one-half small-width">
                            <ReviewCard
                                id="epk3al87mt"
                                thumbnailUrl={require('../../images/reviews/kinetic.jpg?image_crop_resized=640x358')}
                                clientCompany="Kinetic Technology Group"
                                clientName="Brett Harryman"
                                description="It is the easiest and easiest to look at platform by any means out of any of the other CRMs out there on the market"
                            />
                        </Col>
                    </Row>
                    <Row className="review-row flexed-mobile stretch review-row-2">
                        <Col className="one-half small-width">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelectorAll(
                                            '.review-row-2 .review-card'
                                        )
                                        .forEach(card => {
                                            card.classList.add('slide-up');
                                        });
                                }}
                            />
                            <ReviewCard
                                id="sapdbccr7g"
                                thumbnailUrl={require('../../images/reviews/brainlink.jpg?image_crop_resized=640x358')}
                                clientCompany="Brainlink"
                                clientName="Raj Goel"
                                description="I love that my marketing information isn't siloed into yet another vendor portal."
                            />
                        </Col>
                        <Col className="one-half small-width">
                            <ReviewCard
                                id="oom72wkv0t"
                                thumbnailUrl={require('../../images/reviews/it-business-hero.jpg?image_crop_resized=640x358')}
                                clientCompany="IT Business Hero"
                                clientName="Raul Olave"
                                description="It was a breath of fresh air that we had a CRM system that could not only do automated market research, but also integrate with all the platforms that we use on a daily basis."
                            />
                        </Col>
                    </Row>
                    <Row className="review-row flexed-mobile stretch review-row-1 z-index-999">
                        <Col className="one-half small-width">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelectorAll(
                                            '.review-row-1 .review-card'
                                        )
                                        .forEach(card => {
                                            card.classList.add('slide-up');
                                        });
                                }}
                            />
                            <ReviewCard
                                id="ozgkfd61tb"
                                thumbnailUrl={require('../../images/reviews/david-sewell.png?image_crop_resized=640x358')}
                                clientCompany="Sewelltech, Inc."
                                clientName="David Sewell"
                                description="GlassHive does a lot of the heavy lifting. ﻿They have a great platform to use and instruct you on how to use it. The software is really easy to use. It’s intuitive, has a nice design, easy to get in, very responsive, and you can find information that you need very quickly."
                            />
                        </Col>
                        <Col className="one-half small-width">
                            <ReviewCard
                                id="3qgvs7si5t"
                                thumbnailUrl={require('../../images/reviews/murat-guvenc.png?image_crop_resized=640x358')}
                                clientCompany="Accuras"
                                clientName="Murat Guvenc"
                                description="GlassHive is the automation piece to our sales follow-up. You can build the collateral and the templates but in order to run it, you need the timelines and workflows. The email journeys from GlassHive provide that workflow automation and visibility after the execution."
                            />
                        </Col>
                    </Row>
                    <Row className="review-row flexed-mobile stretch review-row-1 z-index-999">
                        <Col className="one-half small-width">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelectorAll(
                                            '.review-row-1 .review-card'
                                        )
                                        .forEach(card => {
                                            card.classList.add('slide-up');
                                        });
                                }}
                            />
                            <ReviewCard
                                id="efy3m7fg52"
                                thumbnailUrl={require('../../images/reviews/charles-levoir.png?image_crop_resized=640x358')}
                                clientCompany="Caden Technologies"
                                clientName="Charles LeVoir"
                                description="We were able to engage with a dedicated GlassHive representative that helped us through the platform and having the combonation of ready-made-marketing material and an easy-to-use platform to navigate, was for us, a winning combonation."
                            />
                        </Col>
                        <Col className="one-half small-width">
                            <ReviewCard
                                id="xj41353uh2"
                                thumbnailUrl={require('../../images/reviews/paul-north.png?image_crop_resized=640x358')}
                                clientCompany="Global Computer Systems"
                                clientName="Paul North"
                                description="What I really love about GlassHive is the automations. I can see the progress of the campaign through GlassHive, the different chains and different avenues that the email has gone through, whether it’s been opened or not, all automated, which is key for us."
                            />
                        </Col>
                    </Row>
                    <Row className="review-row flexed-mobile stretch review-row-2">
                        <Col className="one-half small-width">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelectorAll(
                                            '.review-row-2 .review-card'
                                        )
                                        .forEach(card => {
                                            card.classList.add('slide-up');
                                        });
                                }}
                            />
                            <ReviewCard
                                id="sa76khjb1g"
                                thumbnailUrl={require('../../images/reviews/rex-knight.png?image_crop_resized=640x358')}
                                clientCompany="Hi-Link Technology Group"
                                clientName="Rex Knight"
                                description="GlassHive has helped me simplify the sales process by giving me tools that I can zero in on. I found that GlassHive has a lot of good, talented folks that can help me use this CRM so that it’s not an unsurmountable thing we don’t want to do. I’ve tried others before and found that GlassHive has the best training and help for us."
                            />
                        </Col>
                        <Col className="one-half small-width">
                            <ReviewCard
                                id="udbe01i85t"
                                thumbnailUrl={require('../../images/reviews/gary-mcmillan.png?image_crop_resized=640x358')}
                                clientCompany="Onexte Consulting"
                                clientName="Gary McMillan"
                                description="There were two main things that were apparent immediately when working with GlassHive. One was the responsiveness of the people in the organization. ﻿The customer service and level of support we get is unparalleled. Secondly, the platform itself is very intuitive and easy to use. It was very simple to work with the organization and very simple to work with the product."
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="pink-swish-background container-1"></Container>

                {/* <Container className="pink-mid">
          <Row className="review-row-4">
            <Waypoint
              onEnter={() => {
                document
                  .querySelectorAll(".review-row-4 .review-card")
                  .forEach(card => {
                    card.classList.add("slide-up")
                  })
              }}
            />
            <Col className="">
              <ReviewCardFull
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="“Our top compliment: Your understanding of Managed Services and how to create content and materials suited to what we do and who we are. There are many other compliments but that is #1.

Thank you for everything you have done for us. We are looking forward to a long relationship between Affinity IT Group and TRIdigital.”"
              />
            </Col>
          </Row>
        </Container> */}
                {/* <Container className="z-index-999">
          <Row className="flexed-mobile stretch review-row-5">
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                thumbnailUrl="https://embed-ssl.wistia.com/deliveries/ba464113ff14253b8164725ba1b62297.jpg"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
            <Waypoint
              onEnter={() => {
                document
                  .querySelectorAll(".review-row-5 .review-card")
                  .forEach(card => {
                    card.classList.add("slide-up")
                  })
              }}
            />
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                thumbnailUrl="https://embed-ssl.wistia.com/deliveries/ba464113ff14253b8164725ba1b62297.jpg"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
          </Row>
          <Row className="flexed-mobile stretch review-row-6">
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                thumbnailUrl="https://embed-ssl.wistia.com/deliveries/ba464113ff14253b8164725ba1b62297.jpg"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
            <Waypoint
              onEnter={() => {
                document
                  .querySelectorAll(".review-row-6 .review-card")
                  .forEach(card => {
                    card.classList.add("slide-up")
                  })
              }}
            />
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                thumbnailUrl="https://embed-ssl.wistia.com/deliveries/ba464113ff14253b8164725ba1b62297.jpg"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
          </Row>
          <Row className="review-row-7">
            <Waypoint
              onEnter={() => {
                document
                  .querySelectorAll(".review-row-7 .review-card")
                  .forEach(card => {
                    card.classList.add("slide-up")
                  })
              }}
            />
            <Col className="">
              <ReviewCardFull
                id="39haizsr07"
                thumbnailUrl="https://embed-ssl.wistia.com/deliveries/ba464113ff14253b8164725ba1b62297.jpg"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
          </Row>
          <Row className="flexed-mobile stretch review-row-8">
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
            <Waypoint
              onEnter={() => {
                document
                  .querySelectorAll(".review-row-8 .review-card")
                  .forEach(card => {
                    card.classList.add("slide-up")
                  })
              }}
            />
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
          </Row>
        </Container>
        <Container className="blue-section">
          <Row className="flexed-mobile stretch review-row-9">
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
            <Waypoint
              onEnter={() => {
                document
                  .querySelectorAll(".review-row-9 .review-card")
                  .forEach(card => {
                    card.classList.add("slide-up")
                  })
              }}
            />
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
          </Row>
          <Row className="flexed-mobile stretch review-row-10">
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
            <Waypoint
              onEnter={() => {
                document
                  .querySelectorAll(".review-row-10 .review-card")
                  .forEach(card => {
                    card.classList.add("slide-up")
                  })
              }}
            />
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
          </Row>
          <Row className="flexed-mobile stretch review-row-11">
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
            <Waypoint
              onEnter={() => {
                document
                  .querySelectorAll(".review-row-11 .review-card")
                  .forEach(card => {
                    card.classList.add("slide-up")
                  })
              }}
            />
            <Col className="one-half small-width">
              <ReviewCard
                id="39haizsr07"
                clientCompany="Tridigital Marketing"
                clientName="Scott Mitchell"
                description="Honey is doing what all of the big dogs on the market are doing and adding more every single week. And that's just something you can't really find with those big companies."
              />
            </Col>
          </Row>
        </Container> */}
                <Container className="purple-section">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.join-glasshive-callout')
                                .classList.add('animate');
                        }}
                    />
                    <Row className="center purple-row">
                        <Col className="three-fourth">
                            <JoinGlassHiveCallout />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default IndexPage;
